$(function(){
	$('.user-panel .avatar, .user-panel .nickname').click(function(){
		var _panel = $(this).parent();
		//console.log(_panel);
		_panel.toggleClass('active');
	});
	/*
	$('.user-panel .logout').click(function(){
		$.ajax({
			type: 'GET',
			url: 'https://account.gnstgt.dev/api/auth/logout',
			success: function(data) {
				document.location.href = '/login';
			}
		});
	});*/

	$('.beta-notice .badge').click(function(){
		$('body').toggleClass('show-beta-notice');
	});
});
