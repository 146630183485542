$(function(){

	$('.default-ajax-button').click(function(e){

		var url = $(this).data('ajax-url');
		var method = $(this).data('ajax-method') || 'post';
		var successRedirectUrl = $(this).data('success-redirect');
		var needsConfirmation = $(this).hasClass('needs-confirmation');

		var data = $(this).data('ajax-data');

		$('body').addClass('loading');

		if(needsConfirmation == false || confirm("Are you shure?") === true) {
			$.ajax({
				type: method,
				url: url,
				data: data
			})
			.done(function(data) {
				if(successRedirectUrl) {
					document.location = successRedirectUrl;
				}
			})
			.fail(function(xhr) {
				var data = JSON.parse(xhr.responseText);
				if(data && data.error) {
					alert(data.error);
				}
			})
			.always(function() {
				$('body').removeClass('loading');
			});
		}

		e.preventDefault();
		return false;
	});
});